/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper.js, siehe https://ionicframework.com/docs/angular/slides */
@import "~swiper/scss";
/* Swiper Modules */
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";

@import "~@ionic/angular/css/ionic-swiper";

/* Fonts... */

/*
@font-face {
  font-family: "SF Pro Display";
  src: url("assets/fonts/SF-Pro-Display-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("assets/fonts/SF-Pro-Display-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("assets/fonts/SF-Pro-Display-RegularItalic.otf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("assets/fonts/SF-Pro-Display-BoldItalic.otf");
  font-weight: bold;
  font-style: italic;
}
*/

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

/* */

html,
body {
  font-family: "Roboto";
  height: 100%;
  max-width: 564px; //444px;
  /*height: 946px;*/
  /*max-width: 390px;*/
  /* 1280px 800px */
  background: #ffffff;
}

html {
  display: table;
  margin: auto;
}

body {
  /*
  display: table-cell;
  vertical-align: middle;
  */
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-app {
  max-width: 540px; //390px;
  //max-height: 844px;
  max-height: 100%;
  height: auto;
  background: #FFFFFF; //url(./assets/images/app_bg_blurred.svg) center/100% no-repeat;
}

@media screen and (min-width: 600px) {
  ion-app {
    max-height: 92%;
  }
}

@media screen and (min-width: 600px) {
  ion-app {
    margin-top: 30px;
    /*33px;*/
    margin-left: 12px;
    /*28px;*/
  }
}

ion-content {
  /*--background: url(./assets/images/appbg.svg) center/100% no-repeat;*/
  --background: transparent;
}

.transparent-modal {
  --background: transparent;

  &::part(backdrop) {
    /*background-color: red;*/
  }

  &::part(content) {
    /*pointer-events: none;*/
    height: auto;
  }

  /*ion-content {
    --background: transparent;
  }*/
}

/* *********************************************************************************************** */

ion-header {
  width: auto;
  background: transparent;

  /* Header = ion-toolbar */
  .Header {
    border-radius: 0px 0px 45px 45px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 100px;
    //--min-height: 100%;
    width: auto;

    --background: var(--ion-color-header-background); // #ffffff;
    --border-width: 0;

    padding-top: 24px !important;

    ion-item {
      --background: transparent;

      ion-img {
        margin-left: 25px;
        margin-right: 20px;
        background: blueviolet; // ????
        --background: blueviolet; // ????
      }

      ion-label {
        margin-left: 0px;
        background: greenyellow; // ????
        --background: greenyellow; // ????
      }
    }
  }

  

  .Search {
    --background: transparent;
    border-radius: 0;
    margin: 16px 0px 0px 0px;
    height: auto;

    ion-searchbar.custom {
      background: transparent;
      --placeholder-color: rgba(47, 78, 168, 0.80);
      --icon-color: #2F4EA8;
  
      filter: drop-shadow(0px 3px 3px rgba(47, 78, 168, 0.50));
  
      .searchbar-input-container {
        background: transparent;
        border-radius: 11px;
      }

      .searchbar-input {
        padding-inline-start: 40px !important;
        color: #2F4EA8;
        background: #FFFFFF;
        height: 48px;
        border: 1px solid #2F4EA8;
      }

      .searchbar-search-icon {
        margin-inline-start: 10px !important;
        color: #2F4EA8 !important;
        padding-top: 6px;
      }

      .searchbar-clear-button {
        color: #2F4EA8 !important;
        padding-top: 10px;
      }
    }
    
  }

  .HeaderAdditionals {

    width: auto;
    height: 48px;
    margin: 0 32px;
    padding: 12px 0 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-family: var(--system-ui);
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    font-style: normal;
    
    color: var(--ion-color-text-blue);

    .parts {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    ion-text {
      margin: 0px 8px 0px 0px;
      cursor: pointer;
    }

    ion-icon {
      font-size: 12px;
      cursor: pointer;
    }

    ion-text.disable,
    ion-icon.disable {
      cursor: default;
    }

    ion-text.disable {
      //color: #cccccc;
      opacity: 0.3;
    }

    ion-icon.disable {
      opacity: 0.3;
    }
  }

  .Sort {
    font-family: var(--system-ui);
    font-size: 14px;
    line-height: 19px;
    font-style: normal;
    font-weight: 700;
    color: var(--ion-color-text-blue); //#ffffff;

    ion-text {
      margin: 0px 8px 0px 0px;
      cursor: pointer;
    }

    ion-icon {
      font-size: 14px;
      cursor: pointer;
    }

    ion-text.disable,
    ion-icon.disable {
      color: #cccccc;
      cursor: default;
    }
  }
}

ion-back-button {
  --color: var(--ion-color-button-background) !important;
  --icon-font-size: 32px;
  --margin-start: 0px;
  --padding-start: 0px;
  /* Abstand nach links */
  --icon-margin-start: 24px;
  --icon-padding-start: 0px;

  --margin-end: 0px;
  /* Abstand zum HeaderTitle Text */
  --padding-end: 20px;
}

ion-menu-button {
  --color: var(--ion-color-menu-button);
  font-size: 28px;
  /* u.a. Abstand nach rechts */
  margin: 4px 14px 0px 0px !important;
}

.HeaderTitle {
  font-family: var(--system-ui);;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  font-weight: 600;
  color: var(--ion-color-header-text);
}

.HeaderSubTitle {
  font-family: var(--system-ui);;
  font-size: 18px;
  line-height: 25px;
  font-style: normal;
  font-weight: 800;
  color: var(--ion-color-text-blue); //#ffffff;
}

/* *********************************************************************************************** */

@media only screen and (max-width: 480px) {
  /*.CardTitle {
    font-size: 1.0rem !important;
  }*/
}

.CardList {
  background: transparent;
}

.InfoText {
  --background: transparent;
  font-family: var(--system-ui);;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.InfoText:first-child {
  margin-top: 30px;
  width: 80%;
}

/* ***********************************************************************************************

   Scrollbar

*/

@media screen and (min-width: 600px) {
}

ion-content {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0a3e6c;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-color: #0a3e6c transparent;
  scrollbar-width: thin;
}


.HigherItem {
  padding-bottom: 12px;
}